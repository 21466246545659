
























import Vue from "vue";
export default Vue.extend({
  name: "Why",
  data() {
    return {
      items: [
        "Porque es una guía completa y especializada que ha logrado posicionamiento, recordación y uso efectivo entre quienes programan, organizan y operan eventos.",
        "Porque es una publicación seria, responsable y con trabajo de investigación permanente que genera confiabilidad y seguridad en la información contenida.",
        "Porque aquí se encuentran registrados la gran mayoría de proveedores para eventos que serán consultados por los organizadores y operadores de eventos.",
        "Porque su empresa merece ser destacada y posicionada preferencialmente en un directorio especializado que se ofrece en formato impreso y virtual.",
        "Porque esta publicación llega objetivamente a empresas y personas que realizan eventos.",
      ],
    };
  },
});
